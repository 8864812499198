/* eslint-disable no-param-reassign */
import { Page } from "@frontend/common/src/types/Page";
import { Context } from "..";

export const openLogin = async ({ state }: Context) => {
  state.navigation.currentPage = Page.LOGIN;
};

export const openForgotPassword = async ({ state }: Context) => {
  state.navigation.currentPage = Page.RESET_PASSWORD;
};

export const openHome = async ({ state }: Context) => {
  state.navigation.currentPage = Page.HOME;
};

export const openTechnicalServices = async ({ state }: Context) => {
  state.navigation.currentPage = Page.TECHNICAL_SERVICES;
};

export const openOrderList = async (
  { state, effects }: Context,
  routeArgs: any
) => {
  state.navigation.currentPage = Page.ORDER_LIST;

  if (routeArgs.params && routeArgs.params.side) {
    state.orders.currentPage = parseInt(routeArgs.params.side, 10);
  } else {
    state.orders.currentPage = 1;
  }

  try {
    state.orders.orders = await effects.api.order.getAllForCustomerId(
      state.customer.id
    );
  } catch (error) {
    console.log(error);
  }
};

export const openViewOrder = async (
  { state, effects }: Context,
  routeArgs: any
) => {
  state.navigation.currentPage = Page.VIEW_ORDER;
  state.orders.currentOrderId = parseInt(routeArgs.params.id, 10);

  try {
    state.orders.orders = await effects.api.order.getAllForCustomerId(
      state.customer.id
    );
    if (
      !state.orders.orders.find(
        (order) => order.id === state.orders.currentOrderId
      )
    ) {
      state.error = { message: "Fant ikke ordre" };
      state.navigation.currentPage = Page.ERROR;
    } else {
      state.orders.currentOrder.documents =
        await effects.api.order.getDocumentsForOrder(
          state.customer.id,
          state.orders.currentOrderId
        );
    }
  } catch (error) {
    console.log(error);
  }
};

export const openViewOrderProject = async (
  { state, effects }: Context,
  routeArgs: any
) => {
  state.navigation.currentPage = Page.VIEW_ORDER_PROJECT;
  state.orders.currentOrderProjectId = parseInt(routeArgs.params.id, 10);

  try {
    state.orders.orders = await effects.api.order.getAllForCustomerId(
      state.customer.id
    );
    if (
      !state.orders.orders.find(
        (order) => order.id === state.orders.currentOrderProjectId
      )
    ) {
      state.error = { message: "Fant ikke ordre" };
      state.navigation.currentPage = Page.ERROR;
    } else {
      state.orders.currentOrderProject.documents =
        await effects.api.order.getDocumentsForOrder(
          state.customer.id,
          state.orders.currentOrderProjectId
        );
    }
  } catch (error) {
    console.log(error);
  }
};

export const openUser = async ({ state }: Context) => {
  state.navigation.currentPage = Page.USER;
};
export const openCompanyPage = async ({ state }: Context) => {
  state.navigation.currentPage = Page.COMPANY;
};

export const openDocumentationPage = async ({ state }: Context) => {
  state.navigation.currentPage = Page.DOCUMENTATION;
};
