/* eslint-disable import/no-duplicates */
import { format as fnsFormat, parseISO, parse } from "date-fns";
import { nb } from "date-fns/locale";

// eslint-disable-next-line no-shadow
export enum DateFormat {
  // eslint-disable-next-line no-unused-vars
  LONG = "d. LLL yyyy",
  // eslint-disable-next-line no-unused-vars
  ISO_DATE = "yyyy-MM-dd",
  REGULAR = "dd.MM.yyyy",
}

export function string2date(date: string): Date {
  return parseISO(date);
}

export function parsePretre(date: string): string {
  if (!date || !date.trim().length) {
    return null;
  }
  return parse(date, "MM/dd/yyyy HH:mm:ss", new Date()).toISOString();
}

export function formatDate(
  date: string | Date,
  dateFormat = DateFormat.REGULAR
) {
  let d = date;
  if (!d || (typeof d === "string" && !d.trim().length)) {
    return null;
  }

  if (typeof date === "string") {
    d = parseISO(date);
  }
  const test = fnsFormat(d as Date, dateFormat, { locale: nb });
  return test;
}
