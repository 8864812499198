import styled from "styled-components";

export const FormControl = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const Error = styled("p")`
  color: red;
  margin-top: 0.5rem;
  &:first-letter {
    text-transform: uppercase;
  }
`;
