import React, { FunctionComponent } from "react";
import { Column, Table } from "@frontend/common/src/components/Table/Table";
import { useTranslation } from "react-i18next";
import { Link } from "../../elements/Link/Link";
import { Document } from "../../types/Document";
import { useActions } from "../../overmind";
import { HBox } from "../../elements/HBox/HBox";

type Props = {
  documents: Document[];
};

export const DocumentList: FunctionComponent<Props> = ({ documents }) => {
  const { t } = useTranslation();
  const actions = useActions();

  const columns: Column<Document>[] = [
    {
      headerName: t("Description"),
      field: "description",
      render: (row) => (
        <HBox>
          <Link
            as="span"
            onClick={() => {
              actions.orders.openDocument(row);
            }}
          >
            {row.description}
          </Link>
          <Link
            as="span"
            onClick={() => {
              actions.orders.downloadDocument(row);
            }}
          >
            Last ned
          </Link>
        </HBox>
      ),
    },
    /*
      {
        headerName: t("Doc type"),
        field: "type",
      },
      {
        headerName: t("Folder"),
        field: "folder",
      },
      */
  ];
  return (
    <Table
      noResultsText="Ingen dokumenter"
      keyField="id"
      data={documents}
      availableColumns={columns}
      columns={columns}
    />
  );
};
