import React, { FunctionComponent } from "react";
import { Column, Table } from "@frontend/common/src/components/Table/Table";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/types/Page";
import { formatDate } from "@frontend/common/src/helpers/date";
import { Order } from "../../types/Order";
import { Link } from "../../elements/Link/Link";

import { createUrl } from "../../app/pages";
import { RootState, useActions, useAppState } from "../../overmind";
import { formatMoney } from "../../helpers/money";
import { OrderStatus } from "../OrderStatus/OrderStatus";
import { getDeliveryDateText } from "../../helpers/order";

type ColumnKey = keyof Order;

type Props = {
  orders: Order[];
  columns?: ColumnKey[];
  noResultsText?: string;
};

const createColumns = (
  actions: any,
  state: RootState,
  t: any
): Column<Order>[] => {
  const allColumns: Column<Order>[] = [
    {
      headerName: t("Project no."),
      field: "projectId",
      // eslint-disable-next-line consistent-return
      render: (row) => {
        const masterProject = state.orders.masterProjects[row.projectId];
        if (masterProject) {
          return (
            <Link
              href={createUrl(Page.VIEW_ORDER_PROJECT, { id: masterProject })}
            >
              {row.projectId}
            </Link>
          );
        }
      },
    },
    {
      headerName: t("Status"),
      field: "status",
      render: (row) => <OrderStatus statusCode={row.status} />,
    },
    {
      headerName: t("Order no."),
      field: "id",
      render: (row) => (
        <Link href={createUrl(Page.VIEW_ORDER, { id: row.id })}>{row.id}</Link>
      ),
    },
    {
      headerName: t("Customer Order no."),
      field: "customerOrderNumber",
    },
    {
      headerName: t("Order date"),
      field: "orderDate",
      render: (row) => formatDate(row.orderDate),
    },
    {
      headerName: t("Delivery"),
      field: "delivery",
      render: (row) => getDeliveryDateText(row),
    },
    {
      headerName: t("Product"),
      field: "productId",
      render: (row) => {
        const product = state.productOptions.find(
          (p) => p.id === row.productId
        );
        return product ? product.name : row.productId;
      },
    },
    {
      headerName: t("Marked"),
      field: "marked",
    },
    {
      headerName: t("Constructor"),
      field: "engineerId",
      render: (row) => {
        const employee = state.pretreEmplyees.find(
          (e) => e.id === row.engineerId
        );
        return employee ? employee.name : "";
      },
    },
    {
      headerName: t("Order confirmation"),
      field: "id",
      render: () => <Link href="#">Klikk for OB</Link>,
    },
    {
      headerName: t("Price"),
      field: "sum",
      alignEnd: true,
      render: (row) => (
        <span style={{ textAlign: "right", float: "right" }}>
          {row.isOrderPriceCalculated && row.sum > 0 ? (
            <span style={{ display: "flex" }}>{formatMoney(row.sum)}*</span>
          ) : (
            formatMoney(row.sum)
          )}
        </span>
      ),
    },
  ];

  return allColumns;
};

export const OrderList: FunctionComponent<Props> = ({
  orders,
  columns,
  noResultsText,
}) => {
  const { t } = useTranslation();
  const actions = useActions();
  const state = useAppState();
  const allColumns = createColumns(actions, state, t);
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }
  return (
    <Table
      noResultsText={noResultsText}
      keyField="id"
      data={orders}
      columns={columnsToUse}
      availableColumns={allColumns}
      onSortChanged={(field, direction) =>
        actions.orders.setSortOption({ field, direction })
      }
      currentSortOption={state.orders.currentOrderSortOption}
    />
  );
};
