import React, { FunctionComponent } from "react";
import { Page } from "@frontend/common/src/types/Page";
import { useAppState } from "../../../overmind";

import { MainMenu } from "../components/MainMenu";

type Props = {
  children: any;
  currentPage: Page;
};

export const MainLayout: FunctionComponent<Props> = ({
  children,
  currentPage,
}) => {
  const state = useAppState();

  let { username } = state.auth.user;

  if (state.currentEmployee) {
    username = `${state.currentEmployee.name} (${state.customer.name})`;
  } else if (state.customer) {
    username = state.customer.name;
  }

  //  customer={state.customer} customerEmployee={state.currentEmployee}
  return (
    <>
      <MainMenu
        currentPage={currentPage}
        user={state.auth.user}
        displayName={username}
      />
      {children}
    </>
  );
};
