import { DraftOrderRoof } from "../types/DraftOrder/DraftOrderRoof";
import { DraftOrderJoists } from "../types/DraftOrder/DraftOrderJoists";
import { DraftOrderPrecut } from "../types/DraftOrder/DraftOrderPrecut";
import { DraftOrder } from "../types/DraftOrder/DraftOrder";

const validateNumber = (value: any) =>
  typeof value === "number" ? value : parseInt(value, 10);

const mapDraftOrderProductsToBackendModel: any = (data: DraftOrder | any) => {
  if (!data) return null;

  return {
    // TODO should use data.product.roof.hasRoof. Currently that breaks the view of ChooseProductPage
    HarTak: data.hasRoof,
    TakType: validateNumber(data?.products?.roof?.roofType),
    Taktekking: validateNumber(data?.products?.roof?.roofingType),
    Takvinkel: validateNumber(data?.products?.roof?.angle),
    Spennvidde: validateNumber(data?.products?.roof?.rangeDistance),
    Utstikk: validateNumber(data?.products?.roof?.protrusion),
    KommentarTak: data?.products?.roof?.comment,
    kraning: data.products?.roof?.additionalCrane === 1,

    HarBjelkelag: data.hasJoists,
    BjelkelagType: validateNumber(data?.products?.joists?.joistsType),
    BjelkelagValg: validateNumber(data?.products?.joists?.joistsChoice),
    NedsenkVaatrom: validateNumber(data?.products?.joists?.submergeWetRoom),
    KommentarBjelkelag: data?.products?.joists?.comment,

    HarPrecut: data.hasPrecut,
    PrecutDimensjon: validateNumber(data?.products?.precut?.dimensions),
    KommentarPrecut: data?.products?.precut?.comment,
  };
};

export const mapNotatB2b = (
  noteB2b: any
): {
  roof: DraftOrderRoof;
  joists: DraftOrderJoists;
  precut: DraftOrderPrecut;
} => {
  if (!noteB2b) return null;

  let json;
  if (typeof noteB2b === "string") {
    json = JSON.parse(noteB2b);
  } else if (typeof noteB2b === "object") {
    json = noteB2b;
  } else {
    // invalid variable
    return null;
  }

  const model: {
    roof: DraftOrderRoof;
    joists: DraftOrderJoists;
    precut: DraftOrderPrecut;
  } = { roof: null, joists: null, precut: null };

  model.roof = {
    hasRoof: json.HarTak,
    roofType: json.TakType,
    roofingType: json.Taktekking,
    angle: json.Takvinkel,
    rangeDistance: json.Spennvidde,
    protrusion: json.Utstikk,
    comment: json.KommentarTak,
  } as DraftOrderRoof;

  model.joists = {
    hasJoists: json.HarBjelkelag,
    joistsChoice: json.BjelkelagType,
    joistsType: json.BjelkelagValg,
    submergeWetRoom: json.NedsenkVaatrom,
    comment: json.KommentarBjelkelag,
  } as DraftOrderJoists;

  model.precut = {
    hasPrecut: json.HarPrecut,
    dimensions: json.PrecutDimensjon,
    comment: json.KommentarPrecut,
  } as DraftOrderPrecut;

  return model;
};

export const mapDraftOrder = (data: any): DraftOrder => {
  if (!data) return null;

  return {
    orderDate: data.ordredato,
    customerId: data.mottaker_Purring,
    deliveryAddress: data.levadr1,
    city: data.byggekomm,
    mamsl: data.hoh,
    zipCode: data.postnr,
    projectId: data.prosjektNr,
    marked: data.meknordre,
    customerOrderNumber: data.kundesBestillingsnr,
    farmNumber: data.gardsnr,
    subfarmNumber: data.bruksnr,
    offerReference: data.tilbudref,
    deliveryNote: data.levbet,
    preferredDeliveryDate: data.onsketLevering,
    contactPersonSite: data.kontpers2,
    contactPersonPhoneNr: data.tlflevkunde,

    products: mapNotatB2b(data.notatB2B),
  } as DraftOrder;
};

export const mapDraftOrderToBackendModel: any = (
  offerId: number,
  customerId: number,
  order: DraftOrder
) => {
  if (!order) return null;
  const model = {
    kundeId: customerId,
    customerId, // for endpoint
    tilbudNr: offerId,
    kundeKontaktId: order.customerContactId,
    merkes: order.marked,
    kommentar: "",
    levering: order.deliveryTerms,
    kundesBestillingsnr: order.customerOrderNumber,
    // @ts-ignore
    bestbekr: null,

    // @ts-ignore
    onsketLevering: null,
    // @ts-ignore
    leveringsadresse: null,
    byggeplass: {
      adresse: order.deliveryAddress,
      postnummer: order.zipCode?.toString(),
      lat: 0,
      lng: 0,
      gardsnr: order.farmNumber?.toString(),
      bruksnr: order.subfarmNumber?.toString(),
      hoydeOverHavet: order.mamsl,
      sammeSomByggeplass: order.deliveryAddressSameAsBuilding,
      tilkomstSemi: order.arrivalSemi === 1,
      kontpers2: order.contactPersonSite,
      tlflevkunde: order.contactPersonPhoneNr,
    },
    tilbudsvalg: {
      ...mapDraftOrderProductsToBackendModel(order),
    },
    byggtype: validateNumber(order.buildingType),
  };

  model.onsketLevering =
    typeof order.preferredDeliveryDate === "string"
      ? new Date(order.preferredDeliveryDate).toISOString()
      : null;

  if (order.expectedDrawingDate) {
    switch (typeof order.expectedDrawingDate) {
      case "string":
        model.bestbekr = new Date(order.expectedDrawingDate).toISOString();
        break;
      default:
        model.bestbekr = order.expectedDrawingDate.toISOString();
        break;
    }
  }

  let leveringsadresse = {};

  if (!order.deliveryAddressSameAsBuilding) {
    leveringsadresse = {
      adresse: order.deliveryAddress2,
      postnummer: order.zipCode2?.toString(),
      sammeSomByggeplass: order.deliveryAddressSameAsBuilding,
      tilkomstSemi: order.arrivalSemi === 1,
    };
  } else {
    leveringsadresse = {
      adresse: order.deliveryAddress,
      postnummer: order.zipCode?.toString(),
      sammeSomByggeplass: order.deliveryAddressSameAsBuilding,
      tilkomstSemi: order.arrivalSemi === 1,
    };
  }

  model.leveringsadresse = leveringsadresse;
  return model;
};
