import { yupResolver } from "@hookform/resolvers/yup";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { Page } from "@frontend/common/src/elements/Page/Page";
import {
  BlockButton,
  LinkButton,
} from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { LoginCredentials } from "@frontend/common/src/types/LoginCredentials";
import { ButtonGroup } from "../../../components/ButtonGroup/ButtonGroup.styles";

import { useActions, useEffects } from "../../../overmind";
import { Form } from "../UnauthForm.styles";

const loginSchema = yup.object().shape({
  username: yup.string().required("Vennligst fyll inn epost"),
  password: yup.string().required("Vennligst fyll inn passord"),
});

const buildVersion = process.env.REACT_APP_BUILD_VERSION;

export const LoginPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const actions = useActions();
  const effects = useEffects();

  const { register, handleSubmit, errors } = useForm<LoginCredentials>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmitData = (data: LoginCredentials) => {
    actions.auth.login(data);
  };

  // TODO: Sjekk om maxwidth kan ligge i form
  return (
    <Page title={t("Login")}>
      <Form>
        <div style={{ maxWidth: "25rem" }}>
          <FormInput
            htmlFor="username"
            name="username"
            label={t("Email/username")}
            type="text"
            placeholder={t("Email/username")}
            defaultValue=""
            inputRef={register}
            error={errors.username?.message}
          />
          <FormInput
            htmlFor="password"
            name="password"
            label={t("Password")}
            type="password"
            placeholder={t("Password")}
            defaultValue=""
            inputRef={register}
            error={errors.password?.message}
          />

          <ButtonGroup>
            <BlockButton onClick={handleSubmit(onSubmitData)}>
              {t("Log in")}
            </BlockButton>
            <LinkButton
              onClick={() => effects.router.navigateTo("/glemt-passord")}
            >
              {t("Forgot password?")}
            </LinkButton>
          </ButtonGroup>
        </div>
        <p style={{ marginTop: "1rem" }}> Versjon: {buildVersion}</p>
      </Form>
    </Page>
  );
};
