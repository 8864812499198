import { formatDate } from "@frontend/common/src/helpers/date";
import { Order } from "../types/Order";

export const getDeliveryDateText = (order: Order) => {
  if ([52, 53].includes(order.deliveryWeek) && order.deliveryDay % 10 === 9) {
    return "Ukjent leveringsdato";
  }
  if ([10, 20, 30, 40, 50].includes(order.deliveryDay)) {
    return formatDate(order.delivery);
  }
  return `Uke ${order.deliveryWeek}`;
};
