import React, { FunctionComponent } from "react";

import { Grid, Row, Col } from "@frontend/common/src/elements/Grid/Grid";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/elements/Page/Page";

import { formatDate } from "@frontend/common/src/helpers/date";
import { Page as PageConstants } from "@frontend/common/src/types/Page";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { PhoneLink } from "@frontend/common/src/components/PhoneLink/PhoneLink";
import { CMSContent } from "../../../components/CMSContent/CMSContent";
import { DocumentList } from "../../../components/DocumentList/DocumentList";
import { OrderList } from "../../../components/OrderList/OrderList";

import {
  DetailsBox,
  Legend,
  Text,
  Group,
} from "../../../components/DetailsBox/DetailsBox";
import { SketchFabEmbed } from "../../../components/SketchFabEmbed/SketchFabEmbed";
import { useAppState } from "../../../overmind";
import { Link } from "../../../elements/Link/Link";
import { Header } from "../../../elements/Header/Header";
import { createUrl } from "../../../app/pages";
import { OrderStatus } from "../../../components/OrderStatus/OrderStatus";
import { formatMoney } from "../../../helpers/money";
import { getDeliveryDateText } from "../../../helpers/order";

export const ViewOrderPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const {
    pretreEmplyees,
    customerEmployees,
    productOptions,
    orders: { orders, currentOrder, masterProjects },
  } = useAppState();

  if (!currentOrder) {
    return <div>{t("Loading")}</div>;
  }

  const constructingEngieer = pretreEmplyees.find(
    (e) => e.id === currentOrder.engineerId
  );
  const customerContact = customerEmployees.find(
    (e) => e.id === currentOrder.customerEmployeeId
  );
  const product = productOptions.find((p) => p.id === currentOrder.productId);

  const ordersInSameProject = orders.filter(
    (o) =>
      o.projectId &&
      o.projectId === currentOrder.projectId &&
      o.id !== currentOrder.id
  );

  const backLink = masterProjects[currentOrder.projectId]
    ? {
        label: `Prosjekt #${currentOrder.projectId}`,
        url: createUrl(PageConstants.VIEW_ORDER_PROJECT, {
          id: masterProjects[currentOrder.projectId],
        }),
      }
    : null;

  return (
    <Page title={`Ordre #${currentOrder.id}`} backLink={backLink}>
      <Grid>
        <Row>
          <Col size={1}>
            <DetailsBox>
              <tbody>
                <Group>
                  <Legend>{t("Created")}</Legend>
                  <Text>{formatDate(currentOrder.orderDate)}</Text>
                </Group>
                <Group>
                  <Legend>{t("Product")}</Legend>
                  <Text>{product ? product.name : "Ukjent"}</Text>
                </Group>
                <Group>
                  <Legend>{t("Status")}</Legend>
                  <Text>
                    <OrderStatus statusCode={currentOrder.status} />
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Delivery date")}</Legend>
                  <Text>{getDeliveryDateText(currentOrder)}</Text>
                </Group>

                <Group>
                  <Legend>{t("Marked")}</Legend>
                  <Text>{currentOrder.marked}</Text>
                </Group>
                <Group>
                  <Legend>{t("Customer order no")}</Legend>
                  <Text>{currentOrder.customerOrderNumber || "Ikke satt"}</Text>
                </Group>
                <Group>
                  <Legend>{t("Customer ref.")}</Legend>
                  <Text>
                    {customerContact ? customerContact.name : "Ikke satt"}
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Delivery addr.")}</Legend>
                  {currentOrder.deliveryTerms.toLowerCase() === "avhentet" ? (
                    <Text>Avhentet</Text>
                  ) : (
                    <Text>
                      <Link
                        target="_blank"
                        href={`https://www.google.no/maps/search/${encodeURI(
                          currentOrder.deliveryAddress
                        )}`}
                      >
                        {currentOrder.deliveryAddress}
                      </Link>
                    </Text>
                  )}
                </Group>
                <Group>
                  <Legend>{t("Order price")}</Legend>
                  <Text>
                    {currentOrder.isOrderPriceCalculated &&
                    currentOrder.sum > 0 ? (
                      <span style={{ display: "flex" }}>
                        {formatMoney(currentOrder.sum)}
                        <CMSContent contentId={CMSArticles.NOT_CALCULATED} />
                      </span>
                    ) : (
                      formatMoney(currentOrder.sum)
                    )}
                  </Text>
                </Group>
              </tbody>
            </DetailsBox>
          </Col>
          <Col size={1}>
            <DetailsBox>
              <tbody>
                <Group>
                  <Legend>{t("Constructor")}</Legend>
                  <Text>{constructingEngieer.name}</Text>
                </Group>

                <Group>
                  <Legend>{t("Site phone")}</Legend>
                  <Text>
                    <PhoneLink number={currentOrder.constructionContactPhone} />
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Site contact")}</Legend>
                  <Text>{currentOrder.constructionContactName}</Text>
                </Group>
              </tbody>
            </DetailsBox>
          </Col>

          <Col size={1}>
            {currentOrder.embedId ? (
              <SketchFabEmbed
                embedHash={currentOrder.embedHash}
                embedId={currentOrder.embedId}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col size={2}>
            <div style={{ marginBottom: 10 }}>
              <Header type="secondary">{t("Other orders in project")}</Header>
            </div>
            <OrderList
              noResultsText="Ingen andre ordre i prosjektet"
              orders={ordersInSameProject}
              columns={["id", "productId", "status", "delivery", "engineerId"]}
            />
          </Col>

          <Col size={1}>
            <div style={{ marginBottom: 10 }}>
              <Header type="secondary">{t("Documents")}</Header>
            </div>
            <DocumentList documents={currentOrder.documents} />
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};
