import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  nb: {
    translation: {
      Login: "Innlogging",
      "Log in": "Logg inn",
      "Forgot password?": "Glemt passord?",
      Password: "Passord",
      "Email/username": "Epost/brukernavn",
      "My orders and projects": "Mine ordrer og prosjekt",
      "Show my orders and projects": "Vis mine ordrer og prosjekt",
      "Show only projects": "Vis kun prosjekt",
      "Hide delivered orders": "Skjul leverte ordre",
      "Project no.": "Prosjektnr.",
      "Order no.": "Ordrenr.",
      Status: "Status",
      Constructor: "Konstruktør",
      Product: "Produkt",
      "Product Group": "Produktgruppe",
      "Delivery year": "Leveringsår",
      "Delivery date": "Leveringdato",
      "Delivery addr.": "Leveringsaddr.",
      "Delivery terms": "Leverings bet.",
      Delivery: "Levering",
      "Order date": "Ordredato",
      "Order confirmation": "Ordrebekreftelse",
      Price: "Pris",
      "Order price": "Ordrepris",
      "Offer price": "Tilbudspris",
      "My orders": "Mine ordrer",
      "My offers": "Mine tilbud",
      "Technical services": "Tekniske tjenester",
      "Request offer": "Be om tilbud",
      "Offer no.": "Tilbudsnr.",
      "Sales engineer": "Salgsingeniør",
      "Hide processed offers": "Skjul tilbud som er overført til ordre",
      "Show my offers": "Vis mine tilbud",
      Contact: "Kontakt",
      "Offer year": "Tilbudsår",
      Choose: "Velg",
      Logout: "Logg ut",
      Created: "Opprettet",
      "Customer ref.": "Kunde ref.",
      "Customer order no": "Kundes bestillingsnr.",
      Marked: "Merkes",
      Loading: "Laster",
      Phone: "Telefon",
      "Offers in project": "Tilbud i prosjekt",
      "Orders in project": "Ordre i prosjekt",
      "Other orders in project": "Andre ordre i prosjekt",
      "Other offers in project": "Andre tilbud i prosjekt",
      Documents: "Dokumenter",
      "Site contact": "Kontakt byggeplass",
      "Site phone": "Tlf. byggeplass",
      "Other orders for project": "Andre ordre for prosjektet",
      Description: "Beskrivelse",
      Folder: "Mappe",
      "Doc type": "Filtype",
      "My profile - {{companyName}}": "Min profil - {{companyName}}",
      "Customer & site": "Kundeinfo & byggeplass",
      "Offer options": "Tilbudsvalg",
      Attachments: "Vedlegg",
      Summary: "Oppsummering",
      Documentation: "Dokumentasjon",
      "Attach document": "Last opp dokument",
      "Add additional info here": "Skriv tilleggsinformasjon her",
      Done: "Fullfør",
      "Back to my offers": "Tilbake til Mine Tilbud",
      "Back to my orders": "Tilbake til Mine Ordre",
      "Offer created": "Tilbud registrert",
      "Offer date": "Tilbudsdato",
      Customers: "Kunder",
      Username: "Brukernavn",
      Role: "Rolle",
      Email: "Epost",
      Search: "Søk",
      "Want windows installed?": "Ønskes vinduer montert?",
      "Want standard framing?": "Ønskes standard omrammingstype?",
      "Want insolation?": "Ønskes isolert?",
      "Want boarding type: 19x148 D-fals, 60° New type?":
        "Ønskes Kledningstype: 19x148 D-fals, 60° Ny type?",
      "Can vertical boarding be split with floor divider?":
        "Kan stående kledning deles ved etasjeskille?",
      "Special fire safety requirements?": "Er det spesielle brannkrav?",
      "Joist type": "Type bjelkelag",
      "Pick joist": "Velg bjelkelag",
      Joist: "Bjelkelag",
      "Pick joist type": "Velg bjelkelagstype",
      "Immerse wetroom": "Nedsenk våtrom",
      Dimension: "Dimensjon",
      "Pick roof type": "Velg taktype",
      Roof: "Tak",
      Roofing: "Taktekking",
      "Roof angle": "Takvinkel",
      "Range distance": "Spennvidde",
      Protrusion: "Utstikk",
      Elements: "Elementer",
      Yes: "Ja",
      No: "Nei",
      Next: "Neste",
      Comment: "Kommentar",
      "3. What should the offer include?": "3. Hva ønsker du tilbud på?",
      "3. Technical services": "3. Tekniske tjenester",
      "Customer Order no.": "Kundes best.",
      "Project price": "Prosjektpris",
      "Write other required info here": "Skriv annen nødvendig info her",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "nb",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
