import { Product } from "../../../types/Product";
import { ProductGroup } from "../../../types/ProductGroup";
import { BaseService } from "../BaseService";
import { endpoints } from "../endpoints";
import { ILookupsService } from "./ILookupsService";

type LookupResponse = {
  produktGrupper: any[];
  byggTyper: any[];
  tilbudsvalg: any[];
  produktTyper: any[];
  tekniskeTjenester: any[];
  tilbudsStatuser: any[];
  ordreStatuser: any[];
};
export class LookupsService extends BaseService implements ILookupsService {
  async getLookups(): Promise<{
    productTypes: Product[];
    orderStatuses: any[];
    productGroups: any[];
  }> {
    const results: LookupResponse = await this.makeRequest(
      endpoints.lookups.getLookups
    ).then((r) => r.data);

    return {
      productTypes: results.produktTyper.map(LookupsService.convertProductType),
      productGroups: results.produktGrupper.map(
        LookupsService.convertProductGroup
      ),
      orderStatuses: results.ordreStatuser,
    };
  }

  async getConfigValue(configKey: string): Promise<string> {
    return await this.makeRequest(endpoints.lookups.getConfigValue, {
      configKey,
    }).then((r) => r.data);
  }

  static convertProductType(b: any): Product {
    return {
      id: b.produktTypeID,
      name: b.produktTypeNavn.trim(),
      group: b.produktGruppe,
    };
  }

  static convertProductGroup(b: any): ProductGroup {
    return {
      id: b.produktgruppeID,
      name: b.produktgruppe.trim(),
    };
  }
}
