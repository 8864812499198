import React, { FunctionComponent } from "react";

import { Grid, Row, Col } from "@frontend/common/src/elements/Grid/Grid";
import { useTranslation } from "react-i18next";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { formatDate } from "@frontend/common/src/helpers/date";
import { PhoneLink } from "@frontend/common/src/components/PhoneLink/PhoneLink";
import { Link } from "../../../elements/Link/Link";

import { DocumentList } from "../../../components/DocumentList/DocumentList";
import { OrderList } from "../../../components/OrderList/OrderList";

import {
  DetailsBox,
  Legend,
  Text,
  Group,
} from "../../../components/DetailsBox/DetailsBox";
import { SketchFabEmbed } from "../../../components/SketchFabEmbed/SketchFabEmbed";
import { useAppState } from "../../../overmind";
import { Header } from "../../../elements/Header/Header";
import { formatMoney } from "../../../helpers/money";

export const ViewOrderProjectPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { customerEmployees, orders, pretreEmplyees, orderStatuses } =
    useAppState();

  const { currentOrderProject } = orders;
  if (!currentOrderProject) {
    return <div>{t("Loading")}</div>;
  }

  const orderStatus = orderStatuses.find(
    (o) => o.statusNr === currentOrderProject.status
  );
  const constructingEngieer = pretreEmplyees.find(
    (e) => e.id === currentOrderProject.engineerId
  );
  const customerContactPerson = customerEmployees.find(
    (e) => e.id === currentOrderProject.customerEmployeeId
  );

  const ordersInProject = orders.orders.filter(
    (o) => o.projectId === currentOrderProject.projectId
  );
  const projectSum = ordersInProject
    .map((o) => o.sum)
    .reduce((sum, val) => sum + val);

  return (
    <Page title={`Prosjekt #${currentOrderProject.projectId}`}>
      <Grid>
        <Row>
          <Col size={1}>
            <DetailsBox>
              <tbody>
                <Group>
                  <Legend>{t("Created")}</Legend>
                  <Text>{formatDate(currentOrderProject.orderDate)}</Text>
                </Group>

                <Group>
                  <Legend>{t("Marked")}</Legend>
                  <Text>{currentOrderProject.marked}</Text>
                </Group>
                <Group>
                  <Legend>{t("Status")}</Legend>
                  <Text>
                    {orderStatus
                      ? orderStatus.statusNavn
                      : currentOrderProject.status}
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Customer order no")}</Legend>
                  <Text>
                    {currentOrderProject.customerOrderNumber || "Ikke satt"}
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Customer ref.")}</Legend>
                  <Text>
                    {customerContactPerson
                      ? customerContactPerson.name
                      : "Ikke satt"}
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Delivery terms")}</Legend>
                  <Text>{currentOrderProject.deliveryTerms}</Text>
                </Group>
                <Group>
                  <Legend>{t("Delivery addr.")}</Legend>
                  {currentOrderProject.deliveryAddress.toLowerCase() ===
                  "avhentet" ? (
                    <Text>Avhentet</Text>
                  ) : (
                    <Text>
                      <Link
                        target="_blank"
                        href={`https://www.google.no/maps/search/${encodeURI(
                          currentOrderProject.deliveryAddress
                        )}`}
                      >
                        {currentOrderProject.deliveryAddress}
                      </Link>
                    </Text>
                  )}
                </Group>
                <Group>
                  <Legend>{t("Project price")}</Legend>
                  <Text>{formatMoney(projectSum)}</Text>
                </Group>
              </tbody>
            </DetailsBox>
          </Col>
          <Col size={1}>
            <DetailsBox>
              <tbody>
                <Group>
                  <Legend>{t("Constructor")}</Legend>
                  <Text>{constructingEngieer.name}</Text>
                </Group>

                <Group>
                  <Legend>{t("Site phone")}</Legend>
                  <Text>
                    <PhoneLink
                      number={currentOrderProject.constructionContactPhone}
                    />
                  </Text>
                </Group>
                <Group>
                  <Legend>{t("Site contact")}</Legend>
                  <Text>{currentOrderProject.constructionContactName}</Text>
                </Group>
              </tbody>
            </DetailsBox>
          </Col>

          <Col size={1}>
            {currentOrderProject.embedId ? (
              <SketchFabEmbed
                embedHash={currentOrderProject.embedHash}
                embedId={currentOrderProject.embedId}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col size={2}>
            <div style={{ marginBottom: 10 }}>
              <Header type="secondary">{t("Orders in project")}</Header>
            </div>
            <OrderList
              noResultsText="Ingen ordre i prosjektet"
              orders={ordersInProject}
              columns={[
                "id",
                "productId",
                "status",
                "delivery",
                "marked",
                "engineerId",
              ]}
            />
          </Col>

          <Col size={1}>
            <div style={{ marginBottom: 10 }}>
              <Header type="secondary">{t("Documents")}</Header>
            </div>
            <DocumentList documents={currentOrderProject.documents} />
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};
