import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { Link } from "../Link/Link";

import { PreTreColors, LayoutConstants } from "../../styles/constants";

const PageContainer = styled("div")`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
`;

const PageTitle = styled("div")`
  background-color: ${PreTreColors.beis};
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  overflow: hidden;
`;

const PageHeaderWrapper = styled("div")`
  padding: 2rem 2.5rem;
`;

const PageBackLink = styled("div")`
  color: white;
  font-size: 0.1rem;
  display: flex;
  a {
    font-size: 1.5rem;
  }
`;

const BackSvg = styled.svg`
  align-self: center;
  width: 11px;
  height: 12px;
  margin-right: 4px;
`;

const LinkButton = styled(Link)`
  color: white;
  font-size: 0.875rem;
  text-decoration: underline;
  &:hover {
    color: #f2f2f2;
  }
`;

const PageHeader = styled("h1")`
  color: white;

  font-size: 2.625rem;
  font-weight: 700;

  @media only screen and (max-width: ${`${LayoutConstants.sidebarBreak}px`}) {
    font-size: 2rem;
  }
`;

type Props = {
  title?: string;
  backLink?: { label: string; url: string };
  children?: ReactNode;
  headerItems?: ReactNode;
};

type BackLinkProps = {
  label: string;
  url: string;
};

const BackLink: FunctionComponent<BackLinkProps> = ({ label, url }) => {
  return (
    <PageBackLink>
      <BackSvg
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0288 5.10421H3.40251L7.25336 1.25422L5.99914 0L0 6L5.99914 12L7.25336 10.7458L3.40251 6.89579H10.0288V5.10421Z"
          fill="white"
        />
      </BackSvg>
      <LinkButton href={url}>{label}</LinkButton>
    </PageBackLink>
  );
};

export const Page: FunctionComponent<Props> = ({
  title,
  children,
  headerItems,
  backLink,
}) => {
  return (
    <>
      {title ? (
        <PageTitle>
          <PageHeaderWrapper>
            {backLink ? (
              <BackLink url={backLink.url} label={backLink.label} />
            ) : null}
            <PageHeader>{title}</PageHeader>
          </PageHeaderWrapper>

          {headerItems}
        </PageTitle>
      ) : null}
      <PageContainer>{children}</PageContainer>
    </>
  );
};
