/* eslint-disable no-param-reassign */
import { CreateEmployeeMessage } from "@frontend/common/src/types/EmployeeMessage";
import { Page } from "@frontend/common/src/types/Page";
import { Context } from ".";
import { getNavigationRoute } from "../app/pages";
// import { OnInitialize } from "./index";

export const onContactPerson = async (
  { actions, effects }: any,
  createEmployeeMessage: CreateEmployeeMessage
) => {
  const message = await actions.modals.sendMessageModal.open({
    userId: createEmployeeMessage.employeeId,
  });

  if (message && message.length) {
    createEmployeeMessage.message = message;
    try {
      await effects.api.employees.sendMessage(createEmployeeMessage);
      effects.toast("Melding sendt", 5000);
    } catch (error) {
      effects.toast(error, 5000);
    }
  }
};

export const onEditEmployee = async (
  { actions }: Context,
  employeeId: number
) => {
  actions.modals.editEmployeeModal.open({ employeeId });
};

export const promptFileUpload = async (
  { actions, effects }: any,
  offerProjectId: number
) => {
  const files = await actions.modals.fileUploadModal.open({});
  if (files && files.length) {
    try {
      await effects.api.offers.addFiles(offerProjectId, files);
      effects.toast("Filer er lastet opp", 5000);
    } catch (error) {
      effects.toast("Feil ved opplasting", 5000);
    }
  }
};

export const openSelectionGridModal = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { actions, effects, state }: Context,
  params: { items: any[]; onSelected: (item: any) => void }
) => {
  const { items, onSelected } = params;
  const selectedItem = await actions.modals.selectionGridModal.open({ items });

  if (selectedItem && onSelected) {
    onSelected(selectedItem);
  }
};

export const onResetPassword = async (
  { state, effects }: Context,
  customerEmployeeId: number
) => {
  try {
    await effects.api.customerEmployee.resetPassword(
      state.customer.id,
      customerEmployeeId
    );
    effects.toast("Tilbakestilt passord", 5000);
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

export const onInitializeOvermind = async ({
  state,
  actions,
  effects,
}: Context) => {
  const cms = await effects.cms.fetch(
    "https://www.pretre.no/kundeportal/?xml=document"
  );
  state.cms = cms;
  const token = effects.tokenService.get();

  try {
    if (token) {
      const isTokenValid = await effects.api.auth.checkToken();
      if (isTokenValid) {
        await actions.auth.onLoggedIn(token);
      } else {
        await effects.tokenService.clear();
      }
    }
  } catch (error) {
    // TODO: We do not care if we are unable to verify token, but we clear the token.
    await effects.tokenService.clear();
  }

  state.isInitialLoadComplete = true;

  const { router } = effects;

  router.route(getNavigationRoute(Page.HOME), actions.navigation.openHome);

  router.route(getNavigationRoute(Page.LOGIN), actions.navigation.openLogin);
  router.route(
    getNavigationRoute(Page.RESET_PASSWORD),
    actions.navigation.openForgotPassword
  );

  router.route(
    getNavigationRoute(Page.ORDER_LIST),
    actions.navigation.openOrderList
  );
  router.route(
    getNavigationRoute(Page.VIEW_ORDER),
    actions.navigation.openViewOrder
  );
  router.route(
    getNavigationRoute(Page.VIEW_ORDER_PROJECT),
    actions.navigation.openViewOrderProject
  );
  try {
    router.start();
  } catch (error) {
    console.error(error);
  }
};
