import { Page } from "@frontend/common/src/types/Page";
import { OrderListPage } from "../pages/order/OrderListPage/OrderListPage";
import { ForgotPasswordPage } from "../pages/Guest/ForgotPasswordPage/ForgotPasswordPage";
import { LoginPage } from "../pages/Guest/LoginPage/LoginPage";
import { HomePage } from "../pages/Home/HomePage";

import { ViewOrderPage } from "../pages/order/ViewOrderPage/ViewOrderPage";
import { ViewOrderProjectPage } from "../pages/order/ViewOrderProjectPage/ViewOrderProjectPage";

import { ErrorPage } from "../pages/error/ErrorPage";

// eslint-disable-next-line no-shadow
export enum PageAccess {
  GUEST,
  AUTH,
}

type PageConfig = {
  component: any;
  restrictedTo: PageAccess | null;
  route: string;
};
export const pages: { [index: string]: PageConfig } = {
  [Page.LOGIN]: {
    component: LoginPage,
    restrictedTo: PageAccess.GUEST,
    route: "/logg-inn",
  },
  [Page.RESET_PASSWORD]: {
    component: ForgotPasswordPage,
    restrictedTo: PageAccess.GUEST,
    route: "/glemt-passord",
  },

  [Page.HOME]: {
    component: HomePage,
    restrictedTo: null,
    route: "/",
  },

  [Page.ORDER_LIST]: {
    component: OrderListPage,
    restrictedTo: PageAccess.AUTH,
    route: "/mine-ordre/:side?",
  },
  [Page.VIEW_ORDER]: {
    component: ViewOrderPage,
    restrictedTo: PageAccess.AUTH,
    route: "/mine-ordre/vis/:id",
  },
  [Page.VIEW_ORDER_PROJECT]: {
    component: ViewOrderProjectPage,
    restrictedTo: PageAccess.AUTH,
    route: "/mine-ordre/prosjekt/:id",
  },
  [Page.ERROR]: {
    component: ErrorPage,
    restrictedTo: null,
    route: "/error",
  },
  [Page.EMULATE]: {
    restrictedTo: PageAccess.AUTH,
    route: "/emulate/:customerId/:customerEmployeeId?",
    component: null,
  },
};

export const getNavigationRoute = (page: Page): string => {
  const p = pages[page];

  if (!pages[page]) {
    throw new Error(`Page not found: ${page}`);
  }

  return p.route;
};

export const createUrl = (
  page: Page,
  params: { [index: string]: string | number } = null
): string => {
  const p = pages[page];

  if (!pages[page]) {
    throw new Error(`Page not found: ${page}`);
  }

  let { route } = p;
  if (params) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in params) {
      route = route.replace(`:${key}`, params[key] as string); // replace required key
      route = route.replace(`:${key}?`, params[key] as string); // replace optional key
    }
  }

  route = route.replace(/:.+\?/gi, "");
  route = route.replace(/\/\//gi, "/");

  return route;
};
