/* eslint-disable no-shadow */
import { derived } from "overmind";
import { Direction } from "@frontend/common/src/components/Table/Direction";
import { filterOrder } from "./helpers/filterOrders";
import { Order, OrderFilter, OrderSortOption } from "../../types/Order";
import { RootState } from "..";
import { DraftOrder } from "../../types/DraftOrder/DraftOrder";

type State = {
  numPerPage: number;
  currentPage: number;
  pageCount: number;
  orders: Order[];

  masterProjects: { [index: number]: any };
  filteredOrders: Order[];
  currentPageItems: Order[];
  orderFilter: OrderFilter;

  currentOrderId: number;
  currentOrder: Order;

  currentOrderProjectId: number;
  currentOrderProject: Order;
  currentOrderSortOption: OrderSortOption;

  lastCompletedOrderStep: number;

  autofill: DraftOrder;
  currentNewOrder: DraftOrder;
};

export const state: State = {
  numPerPage: 20,
  currentPage: 1,
  pageCount: derived((state: State) => {
    return Math.ceil(state.filteredOrders.length / state.numPerPage);
  }),

  orders: [],

  masterProjects: derived((state: State) => {
    const projects: { [index: number]: any } = {};
    state.orders
      .filter((o) => o.isProjectMaster)
      .forEach((o) => {
        projects[o.projectId] = o.id;
      });
    return projects;
  }),

  orderFilter: {
    hideDeliveredOrders: true,
    deliveryYear: null,
  },

  // memoized results of filter
  filteredOrders: derived((state: State, rootState: RootState) => {
    return state.orders.filter((o) =>
      filterOrder(
        o,
        state.orderFilter,
        rootState.currentEmployeeId,
        rootState.productOptions
      )
    );
  }),

  currentOrderSortOption: null,
  currentPageItems: derived((state: State) => {
    let sortedItems: Order[];

    if (state.currentOrderSortOption) {
      sortedItems = state.filteredOrders.sort((a, b) => {
        if (
          a[state.currentOrderSortOption.field] <
          b[state.currentOrderSortOption.field]
        )
          return state.currentOrderSortOption.direction === Direction.ascending
            ? 1
            : -1;
        if (
          a[state.currentOrderSortOption.field] >
          b[state.currentOrderSortOption.field]
        )
          return state.currentOrderSortOption.direction === Direction.ascending
            ? -1
            : 1;
        return 0;
      });
    } else {
      sortedItems = state.filteredOrders;
    }

    if (Math.ceil(state.filteredOrders.length / state.numPerPage) > 1) {
      return sortedItems.slice(
        (state.currentPage - 1) * state.numPerPage,
        (state.currentPage - 1) * state.numPerPage + state.numPerPage
      );
    }
    return sortedItems;
  }),

  currentOrderId: null,
  currentOrderProjectId: null,

  currentOrder: derived((state: State) => {
    return state.orders.find((o) => o.id === state.currentOrderId);
  }),

  currentOrderProject: derived((state: State) => {
    return state.orders.find(
      (o) => o.projectId === state.currentOrderProjectId
    );
  }),

  lastCompletedOrderStep: null,
  autofill: null,
  currentNewOrder: {} as DraftOrder,
};
