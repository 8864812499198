/* eslint-disable prefer-destructuring */
import { parsePretre } from "@frontend/common/src/helpers/date";
import { Document } from "../../../types/Document";
import { Link } from "../../../types/Link";
import { Order } from "../../../types/Order";
import { BaseService } from "../BaseService";
import { endpoints } from "../endpoints";
import { IOrderService } from "./IOrderService";
import { DraftOrder } from "../../../types/DraftOrder/DraftOrder";
import {
  mapDraftOrder,
  mapDraftOrderToBackendModel,
} from "../../../helpers/draftOrder";

export class OrderService extends BaseService implements IOrderService {
  async getDocumentsForOrder(
    customerId: number,
    orderId: number
  ): Promise<Document[]> {
    const results: any[] = await this.makeRequest(
      endpoints.order.getDocumentsforOrder,
      { customerId, orderId }
    ).then((r) => r.data);

    return results.map((r) =>
      OrderService.convertDocument(r, customerId, orderId)
    );
  }

  async getDocument(
    customerId: number,
    orderId: number,
    filename: string
  ): Promise<string> {
    return await this.makeRequest(
      endpoints.order.getDocumentforOrder,
      { customerId, orderId, filename },
      "blob"
    ).then((r) => {
      return r.data;
    });
  }

  async getAllForCustomerId(customerId: number): Promise<Order[]> {
    const results: any[] = await this.makeRequest(
      endpoints.order.getAllForCustomerId,
      { customerId }
    ).then((r) => r.data);

    return results.map(OrderService.convert).sort((a, b) => b.id - a.id);
  }

  async getById(id: number): Promise<Order> {
    const results: any = await this.makeRequest(endpoints.order.getById, {
      id,
    }).then((r) => r.data);
    return OrderService.convert(results);
  }

  async getOrderFromOfferId(
    customerId: number,
    offerId: number
  ): Promise<DraftOrder> {
    const results: DraftOrder = await this.makeRequest(
      endpoints.order.getOrderFromOfferId,
      {
        customerId,
        offerId,
      }
    ).then((r) => mapDraftOrder(r.data));

    return results;
  }

  async commitDraftOrder(
    customerId: number,
    order: DraftOrder,
    offerId?: number
  ): Promise<number> {
    const model = mapDraftOrderToBackendModel(offerId, customerId, order);
    return await this.makeRequest(
      endpoints.order.postCommitDraftOrder,
      model
    ).then((r) => r.data);
  }

  async addFiles(orderId: number, files: FileList): Promise<any> {
    return await this.uploadFile(endpoints.order.addFiles, files, {
      orderId,
    }).then((r) => r.data);
  }

  static convertDocument(
    d: any,
    customerId: number,
    orderId: number
  ): Document {
    return {
      id: d.navn,
      type: d.type,
      folder: d.mappe,
      description: d.navn,
      customerId,
      orderId,
    };
  }

  static convert(order: any): Order {
    let embedHash = null;
    let embedId = null;
    const links: Link[] = [];
    if (order.lenker) {
      order.lenker.forEach((l: any) => {
        if (l.linkType === 1) {
          const embed = l.adresse.match(
            /https:\/\/sketchfab.com\/3d-models\/(.+)-(.+)/i
          );
          if (embed) {
            embedId = embed[1];
            embedHash = embed[2];
          }
        } else {
          links.push(l);
        }
      });
    }
    return {
      id: order.ordrenr,
      projectId: order.prosjektNr !== 0 ? order.prosjektNr : null,
      productId: order.produktType_ID,
      deliveryDay: order.leverdag,
      deliveryWeek: order.leveruke,
      deliveryYear: order.levaar,
      isProjectMaster: order.prosjektMaster !== 0,
      customerOrderNumber: order.kundesBestillingsnr,
      delivery: parsePretre(order.leveringsdato),
      deliveryTerms: order.leveringsbetingelse,
      orderDate: parsePretre(order.ordredato),
      status: order.ordrestatusNr,
      sum: order.kundepris,
      marked: order.merkes,
      deliveryAddress: `${order.levAdre1} ${order.lev_Postnr} ${order.lev_Poststed}`,
      constructionContactPhone:
        order.byggeplass_Tlf !== "0" ? order.byggeplass_Tlf : "",
      constructionContactName: order.byggePlass_Epost,
      customerEmployeeId:
        order.kontaktPerson_ID !== 0 ? order.kontaktPerson_ID : null,
      engineerId: order["konstruktør_ID"],
      isOrderPriceCalculated: order.isOrderPriceCalculated,
      embedHash,
      embedId,
      documents: [],
      links,
    };
  }
}
