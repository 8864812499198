/* eslint-disable no-param-reassign */
import jwt_decode from "jwt-decode";
import { Page } from "@frontend/common/src/types/Page";
import { LoginCredentials } from "@frontend/common/src/types/LoginCredentials";
import { User, UserRole, UserType } from "../../types/User";
import { createUrl } from "../../app/pages";

import { Context } from "..";

export const login = async (
  { effects, actions }: Context,
  credentials: LoginCredentials
) => {
  try {
    const token = await effects.api.auth.login(
      credentials.username,
      credentials.password
    );
    if (token) {
      await actions.auth.onLoggedIn(token);
      if (effects.persistentCache.get("returnUrl")) {
        const returnUrl = effects.persistentCache.get("returnUrl");
        effects.persistentCache.remove("returnUrl");
        effects.router.navigateTo(returnUrl);
      } else {
        effects.router.navigateTo(createUrl(Page.ORDER_LIST));
      }
    } else {
      effects.toast("Innlogging feilet");
    }
  } catch (errorMessage: any) {
    effects.toast(errorMessage, 5000);
  }
};

// called on successfull login
export const onLoggedIn = async (
  { state, effects }: Context,
  token: string
) => {
  const decodedToken: {
    customerId: string;
    nameid: string;
    unique_name: string;
    role: UserRole;
    type: UserType;
  } = jwt_decode(token);

  const user: User = {
    id: parseInt(decodedToken.nameid, 10),
    customerId: parseInt(decodedToken.customerId, 10),
    username: decodedToken.unique_name,
    role: decodedToken.role,
    type: decodedToken.type,
    isAdmin: decodedToken.role === UserRole.ADMIN,
  };

  state.auth.token = token;
  effects.tokenService.set(token);

  state.customer = await effects.api.customer.getById(user.customerId);
  state.pretreEmplyees = await effects.api.employees.getAll();
  state.customerEmployees =
    await effects.api.customerEmployee.getAllForCustomerId(user.customerId);

  const lookups = await effects.api.lookups.getLookups();
  state.productOptions = lookups.productTypes;
  state.productGroups = lookups.productGroups;
  state.orderStatuses = lookups.orderStatuses;

  state.auth.user = user;
  if (user.type === UserType.EMPLOYEE) {
    state.currentEmployeeId = user.id;
  }
};

export const mockLoginWithToken = async (
  { actions }: Context,
  token: string
) => {
  await actions.auth.onLoggedIn(token);
};

export const logout = async ({ state, effects }: Context) => {
  try {
    effects.tokenService.clear();

    state.customer = null;
    state.currentEmployeeId = null;
    state.auth.user = null;

    // effects.router.navigateTo(createUrl(Page.LOGIN));
    // perform hard navigate to clear state.
    window.location.href = createUrl(Page.LOGIN);
  } catch (error: any) {
    alert(error.message);
  }
};

export const forgotPassword = async (
  { effects, actions }: Context,
  credentials: { email: string }
) => {
  try {
    await effects.api.auth.forgotPassword(credentials.email);
    effects.toast("Nytt passord er sendt");
    actions.navigation.openLogin();
  } catch (error: any) {
    effects.toast(error, 5000);
  }
};
