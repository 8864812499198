import React, { FunctionComponent } from "react";
import { Page } from "@frontend/common/src/types/Page";
import { useAppState, useEffects } from "../overmind";

import "./App.css";
import { GuestLayout } from "./layouts/GuestLayout/GuestLayout";
import { MainLayout } from "./layouts/MainLayout/MainLayout";
import { pages, PageAccess, createUrl } from "./pages";

export const App: FunctionComponent = () => {
  const state = useAppState();

  const effects = useEffects();
  let CurrentPage = null;

  if (!state.isInitialLoadComplete) {
    return <div>Laster</div>;
  }
  if (!pages[state.navigation.currentPage]) {
    return <div>404</div>;
  }

  CurrentPage = pages[state.navigation.currentPage];

  if (!CurrentPage) {
    return <div>404</div>;
  }

  const CurrentPageComponent = CurrentPage.component;

  const isGuest = !state.auth.user;

  if (isGuest && CurrentPage.restrictedTo === PageAccess.AUTH) {
    effects.toast("Du må logge inn for å se denne siden");
    effects.router.navigateTo(createUrl(Page.LOGIN));
  } else if (!isGuest && CurrentPage.restrictedTo === PageAccess.GUEST) {
    effects.router.navigateTo(createUrl(Page.HOME));
  }

  if (!isGuest) {
    return (
      <MainLayout currentPage={state.navigation.currentPage}>
        <CurrentPageComponent />
      </MainLayout>
    );
  }
  return (
    <GuestLayout currentPage={state.navigation.currentPage}>
      <CurrentPageComponent />
    </GuestLayout>
  );
};
