import { derived } from "overmind";
import { CMSArticle } from "@frontend/common/src/types/CMSArticle";
import { Employee } from "../types/Employee";
import { CustomerEmployee } from "../types/CustomerEmployee";
import { Customer } from "../types/Customer";
import { Product } from "../types/Product";
import { OrderStatus } from "../types/OrderStatus";
import { ProductGroup } from "../types/ProductGroup";

type State = {
  isInitialLoadComplete: boolean;
  pretreEmplyees: Employee[];
  customerEmployees: CustomerEmployee[];
  customer: Customer;
  currentEmployeeId: number;
  currentEmployee: CustomerEmployee;

  productOptions: Product[];
  productGroups: ProductGroup[];
  orderStatuses: OrderStatus[];

  cms: { [index: number]: CMSArticle };
  error: { message: string };
};

export const state: State = {
  isInitialLoadComplete: false,
  pretreEmplyees: [],
  customerEmployees: [],
  customer: null,
  currentEmployeeId: null,
  // eslint-disable-next-line no-shadow
  currentEmployee: derived((state: State) => {
    return state.customer
      ? state.customerEmployees.find((e) => e.id === state.currentEmployeeId)
      : null;
  }),
  productOptions: [],
  productGroups: [],
  orderStatuses: [],
  cms: {},
  error: null,
};
