import { Customer } from "../../../types/Customer";
import { BaseService } from "../BaseService";
import { endpoints } from "../endpoints";
import { ICustomerService } from "./ICustomerService";

export class CustomerService extends BaseService implements ICustomerService {
  async getById(customerId: number): Promise<Customer> {
    const results: any = await this.makeRequest(endpoints.customer.getById, {
      customerId,
    }).then((r) => r.data);
    return CustomerService.convert(results);
  }

  static convert(customer: any): Customer {
    return {
      id: customer.kundenr,
      name: customer.kundenavn,
      address: customer.adresse1,
      zipcode: customer.postnrk,
      city: customer.poststed,
      email: customer.epost,
      drawingsEmail: customer.epost_Tegninger,
      orderConfimEmail: customer.epost_Bestillingsbekreftelse,
      orderConfrimEmailAlt: customer.epost_Ordrebekreftelse,
      phone: customer.telefon,
      constructionInformation: customer.std_konstr_opplysn,
      salesPersonId: parseInt(customer.ansvarligSelger_ID, 10),
    };
  }
}
