/* eslint-disable no-param-reassign */
import fileDownload from "js-file-download";
import { UserType } from "@frontend/common/src/types/User";
import { Page } from "@frontend/common/src/types/Page";
import { Context } from "..";
import { OrderFilter, OrderSortOption } from "../../types/Order";
import { Document } from "../../types/Document";
import { createUrl } from "../../app/pages";
import { DraftOrder } from "../../types/DraftOrder/DraftOrder";
import { DraftOrderRoof } from "../../types/DraftOrder/DraftOrderRoof";
import { DraftOrderJoists } from "../../types/DraftOrder/DraftOrderJoists";
import { DraftOrderPrecut } from "../../types/DraftOrder/DraftOrderPrecut";
import { CreateOrderSteps } from "../../types/CreateOrderSteps";
import { FileUploadError } from "../../errors/FileUploadError";

export const changeOrderFilter = async (
  { state, effects }: Context,
  change: OrderFilter
) => {
  state.orders.orderFilter = {
    ...state.orders.orderFilter,
    ...change,
  };

  effects.router.navigateTo(
    createUrl(Page.ORDER_LIST, { side: state.orders.currentPage })
  );
};

export const clearOrderFilter = async ({ state }: Context) => {
  state.orders.currentPage = 1;
  state.orders.orderFilter = {
    hideDeliveredOrders: true,
    deliveryYear: null,
    showOnlyMyOrdersAndProjects: state.auth.user.type === UserType.EMPLOYEE,
  };
};

export const downloadDocument = async (
  { effects }: Context,
  file: Document
) => {
  const filename = `${file.folder}${file.description}`;
  const contents = await effects.api.order.getDocument(
    file.customerId,
    file.orderId,
    filename
  );
  fileDownload(contents, file.description);
};

export const setSortOption = async (
  { state }: Context,
  option: OrderSortOption
) => {
  state.orders.currentOrderSortOption = option;
};

export const openDocument = async ({ effects }: Context, file: Document) => {
  const filename = `${file.folder}${file.description}`;
  const contents = await effects.api.order.getDocument(
    file.customerId,
    file.orderId,
    filename
  );
  const fileX = new Blob([contents], { type: "application/pdf;base64" });
  const fileURL = URL.createObjectURL(fileX);
  window.open(fileURL);
};

export const getOrderAutofill = async (
  { state, effects }: Context,
  params: { customerId: number; offerId: number }
) => {
  const data = await effects.api.order.getOrderFromOfferId(
    params.customerId,
    params.offerId
  );

  if (data)
    state.orders.autofill = {
      ...data,
      offerId: params.offerId,
    } as DraftOrder;
};

export const commitDraftOrder = async (
  { state, effects }: Context,
  payload: { customerId: number; order: DraftOrder; offerId?: number }
) => {
  try {
    const orderId = await effects.api.order.commitDraftOrder(
      payload.customerId,
      payload.order,
      payload?.offerId
    );
    state.orders.currentNewOrder.orderId = orderId;

    // upload files
    const files = effects.localFileStorage.get();

    if (orderId && files && files.length) {
      try {
        await effects.api.order.addFiles(orderId, files);
        effects.localFileStorage.set(undefined);
      } catch (error) {
        effects.localFileStorage.set(undefined);
        throw new FileUploadError();
      }
    }

    effects.router.navigateTo(
      createUrl(Page.CREATE_ORDER, { slug: "kvittering" })
    );
  } catch (error) {
    if (error instanceof FileUploadError) {
      effects.toast("Tilbud opprettet, men ikke alle filer ble sendt.", 5000);
    } else {
      effects.toast(error as string, 5000);
    }
  }
};

export const updateDraftOrder = ({ state }: Context, payload: DraftOrder) => {
  state.orders.currentNewOrder = {
    ...state.orders.currentNewOrder,
    ...payload,
  };
};

export const setDraftOfferFiles = ({ effects }: Context, files: FileList) => {
  effects.localFileStorage.set(files);
};

export const setCurrentRoof = ({ state }: Context, payload: DraftOrderRoof) => {
  if (!state.orders.currentNewOrder.products) {
    state.orders.currentNewOrder.products = {} as {
      roof: DraftOrderRoof;
      joists: DraftOrderJoists;
      precut: DraftOrderPrecut;
    };
  }

  if (!state.orders.currentNewOrder.products.roof) {
    state.orders.currentNewOrder.products.roof = {} as DraftOrderRoof;
  }

  state.orders.currentNewOrder.products.roof = {
    ...state.orders.currentNewOrder.products.roof,
    ...payload,
  };
};

export const setCurrentJoists = (
  { state }: Context,
  payload: DraftOrderJoists
) => {
  if (!state.orders.currentNewOrder.products) {
    state.orders.currentNewOrder.products = {} as {
      roof: DraftOrderRoof;
      joists: DraftOrderJoists;
      precut: DraftOrderPrecut;
    };
  }

  if (!state.orders.currentNewOrder.products.joists) {
    state.orders.currentNewOrder.products.joists = {} as DraftOrderJoists;
  }

  state.orders.currentNewOrder.products.joists = {
    ...state.orders.currentNewOrder.products.joists,
    ...payload,
  };
};

export const setCurrentPrecut = (
  { state }: Context,
  payload: DraftOrderPrecut
) => {
  if (!state.orders.currentNewOrder.products) {
    state.orders.currentNewOrder.products = {} as {
      roof: DraftOrderRoof;
      joists: DraftOrderJoists;
      precut: DraftOrderPrecut;
    };
  }

  if (!state.orders.currentNewOrder.products.precut) {
    state.orders.currentNewOrder.products.precut = {} as DraftOrderPrecut;
  }

  state.orders.currentNewOrder.products.precut = {
    ...state.orders.currentNewOrder.products.precut,
    ...payload,
  };
};

export const cancelCreateOrder = ({ effects, state }: Context) => {
  state.orders.currentNewOrder = {} as DraftOrder;
  state.orders.autofill = null;
  state.orders.lastCompletedOrderStep = CreateOrderSteps.CUSTOMER_INFO;
  effects.router.navigateTo(createUrl(Page.HOME));
};

export const clearAutofillAndDraftOrder = ({ state }: Context) => {
  state.orders.currentNewOrder = {} as DraftOrder;
  state.orders.autofill = null;
  state.orders.lastCompletedOrderStep = CreateOrderSteps.CUSTOMER_INFO;
};
