import React, { FunctionComponent } from "react";
import { Page as PageConstants } from "@frontend/common/src/types/Page";
import { CMSArticles } from "@frontend/common/src/types/CMSArticle";
import { CMSContent } from "../../components/CMSContent/CMSContent";
import { PreTreColors, LayoutConstants } from "../../styles/constants";
import { Header } from "../../elements/Header/Header";
import { BlockButton } from "../../components/Buttons/Buttons.styles";

import {
  Page,
  Shader,
  BannerImage,
  Container,
  ItemContainer,
  VerticalCenterBox,
  SubText,
  BtnWrapper,
} from "./HomePage.styles";
import { useAppState, useEffects } from "../../overmind";
import { createUrl } from "../../app/pages";

// TODO Remove this if dont wanna use
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

export const HomePage: FunctionComponent = () => {
  const state = useAppState();
  const effects = useEffects();

  // TODO Remove this if dont wanna use
  const { width } = useWindowDimensions();

  const isGuest = state.auth.user === null;

  return (
    <Page>
      <BannerImage>
        <Shader>
          <Container>
            {/* Left Side */}
            <ItemContainer
              style={{
                textAlign:
                  width >= LayoutConstants.sidebarBreak ? "left" : "center",
              }}
            >
              <VerticalCenterBox>
                <SubText>
                  <CMSContent contentId={isGuest ? 1459 : 1460} />
                </SubText>
                <BtnWrapper
                  style={{
                    maxWidth:
                      width >= LayoutConstants.sidebarBreak ? "200px" : "100%",
                  }}
                >
                  {!isGuest ? (
                    <BlockButton
                      onClick={() =>
                        effects.router.navigateTo(
                          createUrl(PageConstants.ORDER_LIST)
                        )
                      }
                    >
                      Se ordre
                    </BlockButton>
                  ) : (
                    <BlockButton
                      onClick={() =>
                        effects.router.navigateTo(
                          createUrl(PageConstants.LOGIN)
                        )
                      }
                    >
                      Logg inn
                    </BlockButton>
                  )}
                </BtnWrapper>
              </VerticalCenterBox>
            </ItemContainer>
          </Container>
        </Shader>
      </BannerImage>

      <div
        style={{
          width: "80%",
          margin: "50px auto",
          textAlign: "center",
        }}
      >
        <div
          style={{
            maxWidth: 800,
            textAlign:
              width >= LayoutConstants.sidebarBreak ? "left" : "center",
          }}
        >
          <Header>
            Velkommen til <br />
            Pretre AS kundeportal
          </Header>
          <p
            style={{
              color: PreTreColors.beis,
              fontSize: 18,
              fontWeight: 400,
              marginTop: 16,
            }}
          >
            <CMSContent
              contentId={
                isGuest
                  ? CMSArticles.WELCOME_GUEST_2
                  : CMSArticles.WELCOME_LOGGED_IN_2
              }
            />
          </p>
        </div>
      </div>
    </Page>
  );
};
