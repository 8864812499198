import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Grid, Row } from "@frontend/common/src/elements/Grid/Grid";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { useTranslation } from "react-i18next";
import { LinkButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { string2date } from "@frontend/common/src/helpers/date";
import { Select } from "../../../../../components/Select/Select";
import { Checkbox } from "../../../../../components/Checkbox/Checkbox";
import { Order } from "../../../../../types/Order";
import { useActions, useAppState } from "../../../../../overmind";

type Props = {
  orders: Order[];
};

export const OrderFilter: FunctionComponent<Props> = ({ orders }) => {
  const { t } = useTranslation();
  const [projectOptions, setProjectOptions] = useState([]);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const actions = useActions();

  const state = useAppState();

  useEffect(() => {
    const projects: {
      [index: number]: { label: string | number; value: any };
    } = {};
    const people: { [index: number]: { label: string | number; value: any } } =
      {};
    const years: { [index: number]: { label: string | number; value: any } } =
      {};
    const statuses: {
      [index: number]: { label: string | number; value: any };
    } = {};

    orders.forEach((o) => {
      if (o.isProjectMaster) {
        projects[o.projectId] = {
          label: o.projectId,
          value: o.projectId,
        };
      }

      const employee = state.customerEmployees.find(
        (e) => e.id === o.customerEmployeeId
      );

      if (employee) {
        people[employee.id] = {
          label: `${employee.name} `,
          value: employee.id,
        };
      }

      if (o.delivery) {
        const date = string2date(o.delivery);
        years[date.getFullYear()] = {
          label: date.getFullYear(),
          value: date.getFullYear(),
        };
      }

      if (!statuses[o.status]) {
        const status = state.orderStatuses.find((s) => s.statusNr === o.status);
        statuses[o.status] = {
          label: status.statusNavn,
          value: status.statusNr,
        };
      }
    });

    const thisYear = new Date().getFullYear();
    years[thisYear] = {
      label: thisYear,
      value: thisYear,
    };

    setProjectOptions(Object.values(projects).reverse());
    setPeopleOptions(
      Object.values(people).sort((a, b) =>
        `${a.label}`.localeCompare(`${b.label}`)
      )
    );
    setYearOptions(Object.values(years).reverse());
    setStatusOptions(Object.values(statuses));
  }, [orders, state.customerEmployees, state.pretreEmplyees]);

  return (
    <Grid>
      <Row>
        <Col size={1}>
          <Select
            label={t("Project no.")}
            options={projectOptions}
            selectedValue={state.orders.orderFilter.projectId || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                projectId: parseInt(value, 10) || null,
              })
            }
          />
          <FormInput
            label={t("Order no.")}
            type="text"
            name="OrderNumber"
            defaultValue={`${state.orders.orderFilter.orderId || ""}`}
            onChange={(value) =>
              actions.orders.changeOrderFilter({
                orderId: parseInt(value, 10) || null,
              })
            }
          />
          <FormInput
            label={t("Customer Order no.")}
            type="text"
            name="customerOrderNumber"
            defaultValue={`${
              state.orders.orderFilter.customerOrderNumber || ""
            }`}
            onChange={(value) =>
              actions.orders.changeOrderFilter({
                customerOrderNumber: value || null,
              })
            }
          />
          <Select
            label={t("Status")}
            options={statusOptions}
            selectedValue={state.orders.orderFilter.status || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                status: parseInt(value, 10) || null,
              })
            }
          />
        </Col>
        <Col size={1}>
          <Select
            label={t("Contact")}
            options={peopleOptions}
            selectedValue={state.orders.orderFilter.contactId || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                contactId: parseInt(value, 10) || null,
              })
            }
          />
          <Select
            label={t("Product Group")}
            options={state.productGroups.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            selectedValue={state.orders.orderFilter.productGroupId || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                productGroupId: parseInt(value, 10) || null,
              })
            }
          />
          <Select
            label={t("Delivery year")}
            options={yearOptions}
            selectedValue={state.orders.orderFilter.deliveryYear || ""}
            onSelectionChanged={(value) =>
              actions.orders.changeOrderFilter({
                deliveryYear: parseInt(value, 10) || null,
              })
            }
          />
          <FormInput
            label={t("Marked")}
            type="text"
            name="marked"
            defaultValue={`${state.orders.orderFilter.marked || ""}`}
            onChange={(value) =>
              actions.orders.changeOrderFilter({ marked: value })
            }
          />
        </Col>
        <Col
          size={1}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "3.1rem",
          }}
        >
          {state.currentEmployeeId ? (
            <Checkbox
              checked={
                state.orders.orderFilter.showOnlyMyOrdersAndProjects || false
              }
              label={t("Show my orders and projects")}
              onChange={(e) =>
                actions.orders.changeOrderFilter({
                  showOnlyMyOrdersAndProjects: e.target.checked,
                })
              }
            />
          ) : null}
          <Checkbox
            checked={state.orders.orderFilter.showOnlyProjects || false}
            label={t("Show only projects")}
            onChange={(e) =>
              actions.orders.changeOrderFilter({
                showOnlyProjects: e.target.checked,
              })
            }
          />

          <Checkbox
            checked={state.orders.orderFilter.hideDeliveredOrders || false}
            label={t("Hide delivered orders")}
            onChange={(e) =>
              actions.orders.changeOrderFilter({
                hideDeliveredOrders: e.target.checked,
              })
            }
          />
          <LinkButton
            style={{ textAlign: "left" }}
            onClick={() => actions.orders.clearOrderFilter()}
          >
            Tøm filter
          </LinkButton>
        </Col>
      </Row>
    </Grid>
  );
};
