export const sendMessageModal = {
  state: { userId: null as number },
  result: null as string,
};

export const editEmployeeModal = {
  state: { employeeId: null as number },
  result: null as void,
};

export const fileUploadModal = {
  state: {},
  result: null as FileList,
};

export const selectionGridModal = {
  state: { items: null as any[] },
  result: null as any,
};
