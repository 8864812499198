import React, { FunctionComponent } from "react";
import { useAppState } from "../../overmind";

type Props = {
  statusCode: number;
};
export const OrderStatus: FunctionComponent<Props> = ({ statusCode }) => {
  const { orderStatuses, auth } = useAppState();

  const orderStatus = orderStatuses.find((o) => o.statusNr === statusCode);

  if (orderStatus) {
    if (auth.user.isAdmin) {
      return (
        <span
          style={{
            padding: "0.5rem",
            color: orderStatus.farge_Tekst,
            backgroundColor: orderStatus.farge_Bakgrunn,
          }}
        >
          {orderStatus.statusNavn}
        </span>
      );
    }

    return <span>{orderStatus.statusNavn}</span>;
  }

  return <span>{statusCode}</span>;
};
