import {
  MIN_ORDER_STATUS_WHEN_COMPLETED,
  Order,
  OrderFilter,
} from "../../../types/Order";
import { Product } from "../../../types/Product";

const isset = (value: any) => value !== undefined && value !== null;

export function filterOrder(
  order: Order,
  filter: OrderFilter,
  currentUserId: number,
  productIds: Product[]
) {
  if (isset(filter.status) && order.status !== filter.status) {
    return false;
  }

  if (isset(filter.projectId) && order.projectId !== filter.projectId) {
    return false;
  }

  if (
    isset(filter.orderId) &&
    !order.id.toString().includes(filter.orderId.toString())
  ) {
    return false;
  }

  if (
    isset(filter.marked) &&
    !`${order.marked}`.toLowerCase().includes(filter.marked.toLowerCase())
  ) {
    return false;
  }

  if (
    isset(filter.customerOrderNumber) &&
    !order.customerOrderNumber
      ?.toString()
      .includes(filter.customerOrderNumber.toString())
  ) {
    return false;
  }

  if (
    isset(filter.showOnlyMyOrdersAndProjects) &&
    filter.showOnlyMyOrdersAndProjects === true &&
    order.customerEmployeeId !== currentUserId
  ) {
    return false;
  }
  if (isset(filter.productGroupId)) {
    // find the group for the current product
    const currentProduct = productIds.find((p) => {
      return order.productId === p.id;
    });

    // check if the group matches our selection
    if (!currentProduct || currentProduct.group !== filter.productGroupId) {
      return false;
    }
  }
  if (isset(filter.deliveryYear)) {
    const year = order.deliveryYear;

    if (year !== filter.deliveryYear) {
      return false;
    }
  }

  if (
    isset(filter.contactId) &&
    order.customerEmployeeId !== filter.contactId
  ) {
    return false;
  }

  if (
    isset(filter.hideDeliveredOrders) &&
    filter.hideDeliveredOrders === true &&
    order.status >= MIN_ORDER_STATUS_WHEN_COMPLETED
  ) {
    return false;
  }
  if (
    isset(filter.showOnlyProjects) &&
    filter.showOnlyProjects === true &&
    order.isProjectMaster === false
  ) {
    return false;
  }

  return true;
}
