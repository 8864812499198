import styled from "styled-components";
import { Label } from "@frontend/common/src/elements/Label/Label";
import { baseInputStyles } from "../../styles/baseInput";

export const Icon = styled.svg``;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

type StyledCheckboxProps = {
  checked: boolean;
  error?: boolean;
};

export const StyledCheckbox = styled("div")<StyledCheckboxProps>`
  ${baseInputStyles}
  cursor:pointer;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  padding: 0.35rem;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props: any) => (props.checked ? "visible" : "hidden")};
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  ${Label} {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;

    ${StyledCheckbox} {
      margin-right: 0.5rem;
    }
  }
`;
