import { Direction } from "@frontend/common/src/components/Table/Direction";
import { Document } from "./Document";
import { Link } from "./Link";

export const MIN_ORDER_STATUS_WHEN_COMPLETED = 140;

export type Order = {
  id: number;
  projectId?: number;
  productId: number;
  delivery: string;
  deliveryDay: number;
  deliveryWeek: number;
  deliveryYear: number;
  deliveryTerms?: string;
  orderDate: string;
  status: number;
  sum: number;
  marked: string;
  deliveryAddress: string;
  constructionContactPhone: string;
  constructionContactName: string;
  customerOrderNumber: number;
  isProjectMaster: boolean;
  embedHash?: string;
  embedId?: number;
  documents?: Document[];
  links: Link[];
  customerEmployeeId: number;
  engineerId: number;
  isOrderPriceCalculated: boolean;
};

export type OrderFilter = {
  status?: number;
  projectId?: number;
  orderId?: number;
  customerOrderNumber?: number | string;
  productGroupId?: number;
  hideDeliveredOrders?: boolean;
  showOnlyMyOrdersAndProjects?: boolean;
  deliveryYear?: number;
  marked?: string;

  contactId?: number;
  showOnlyProjects?: boolean;
};

export type OrderSortOption = {
  field: keyof Order;
  direction: Direction;
};
