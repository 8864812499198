import { Endpoint, Verb } from "./BaseService";

export const endpoints: { [index: string]: { [index: string]: Endpoint } } = {
  auth: {
    login: {
      path: "/auth/logg-inn",
      verb: Verb.POST,
    },
    checkToken: {
      path: "/auth/sjekk-token",
      verb: Verb.GET,
    },
    forgotPassword: {
      path: "/auth/glemt-passord",
      verb: Verb.POST,
    },
  },
  order: {
    getAllForCustomerId: {
      path: "/kunde/{customerId}/ordre",
      verb: Verb.GET,
    },
    getById: {
      path: "/kunde/{customerId}/ordre/{id}",
      verb: Verb.GET,
    },
    getDocumentsforOrder: {
      path: "/kunde/{customerId}/ordre/{orderId}/filer",
      verb: Verb.GET,
    },
    getDocumentforOrder: {
      path: "/file/{customerId}/filer",
      verb: Verb.POST,
    },
  },
  lookups: {
    getLookups: {
      path: "/oppslag/legacy",
      verb: Verb.GET,
    },
    getConfigValue: {
      path: "/oppslag/config/{configKey}",
      verb: Verb.GET,
    },
  },
  employee: {
    getAll: {
      path: "/ansatt/legacy",
      verb: Verb.GET,
    },
  },
  customerEmployee: {
    getAll: {
      path: "/kunde/{customerId}/kontakt/legacy",
      verb: Verb.GET,
    },
  },
  customer: {
    getById: {
      path: "/kunde/legacy/{customerId}",
      verb: Verb.GET,
    },
  },
};
